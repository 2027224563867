import axios, { AxiosResponse, AxiosRequestConfig,  } from 'axios';
import request from './request';
import * as accounts from './accounts';
import * as breed from './breed';
import * as dashboard from './dashboard';
import * as farms from './farms';
import * as login from './login';
import * as scales from './scales';
import * as serviceGateways from './serviceGateways';
import * as productionPlaces from './prodplaces';
import * as animals from './animals';
import * as groups from './groups';
import * as scaleTypes from './scaleTypes';

const storedSession = localStorage.getItem('hencol-admin-session');
const token = storedSession ? JSON.parse(storedSession).token : null;

const instance = axios.create({
  // baseURL: '/api/v1',
  timeout: 10000,
  headers: {
    'Content-type': 'application/json',
    'Authorization': token ? `Bearer ${token}` : '',
  }
});
// // create an axios instance
// const instance = axios.create({
//   baseURL: '/api/v1',
//   timeout: 10000,
//   headers: { 
//     "Authorization": `Bearer ${token}`,
//   }
//   // transformResponse: (response: ServerResponse) => response.data.payload,
// });

export const service = async (url: string, options: AxiosRequestConfig) => {

  const requestInstance = request(instance);

  const response: any = await requestInstance(url, options);

  return response.data.payload; 

  // return requestInstance(url, options).then((response: any) => response.data.payload);

}

const all = {
  ...accounts,
  ...breed,
  ...dashboard,
  ...farms,
  ...login,
  ...scales,
  ...serviceGateways,
  ...productionPlaces,
  ...animals,
  ...groups,
  ...scaleTypes,
}

export default all 