
import { useDisableScale, useGetScale, useUpdateScale, useReEnableScale } from 'hooks/useScales';
import { useGetScaleTypes } from 'hooks/useScaleTypes';
import { useGetServiceGateways } from 'hooks/useServiceGateways';
import { useParams } from 'react-router-dom';

export default function useScalePage () {

  let { id } = useParams();

  const { isLoading: isFetchingScale, isSuccess: hasFetchedScale, data: scale = {}, refetch: refetchScale } = useGetScale(id);
  const { isLoading: isDisablingScale, isSuccess: hasDisabledScale, mutate: disableScale } = useDisableScale();
  const { isLoading: isReEnablingScale, isSuccess: hasReEnabledScale, mutate: reEnableScale } = useReEnableScale();
  const { isLoading: isUpdatingScale, isSuccess: hasUpdatedScale, mutate: updateScale } = useUpdateScale();
  const { isLoading: isFetchingScaleTypes, isSuccess: hasFetchedScaleTypes, data: scaleTypes = {} } = useGetScaleTypes();
  const { isLoading: isFetchingServiceGateways, isSuccess: hasFetchedServiceGateways, data: serviceGateways = {} } = useGetServiceGateways();

  return {
    isFetchingScale,
    hasFetchedScale,
    scale,
    refetchScale,
    isDisablingScale,
    hasDisabledScale,
    disableScale,
    isReEnablingScale,
    hasReEnabledScale,
    reEnableScale,
    isUpdatingScale,
    hasUpdatedScale,
    updateScale,
    isFetchingScaleTypes,
    hasFetchedScaleTypes,
    scaleTypes,
    isFetchingServiceGateways,
    hasFetchedServiceGateways,
    serviceGateways,
  }

}