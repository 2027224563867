type Country = {
  label: string,
  value: number,
}

export default function (countries: Array<Country>) { 
  
  return [
    {
      name: "farmName",
      label: "Farm name",
      type: "input",
      props: {
        required: true,
      }
    },
    {
      name: "address1",
      label: "Address 1",
      type: "input",
      props: {
        required: true,
      }
    },
    {
      name: "address2",
      label: "Address 2",
      type: "input",
    },
    {
      name: "city",
      label: "City",
      type: "input",
      props: {
        required: true,
      }
    },
    {
      name: "district",
      label: "District",
      type: "input",
    },
    {
      name: "zipCode",
      label: "Zip code",
      type: "input",
    },
    {
      name: "companyName",
      label: "Company name",
      type: "input",
      props: {
        required: true,
      }
    },
    {
      name: "companyRegistration",
      label: "Company registration number",
      type: "input",
      props: {
        required: true,
      }
    },
    {
      name: "email",
      label: "Email",
      type: "input",
    },
    {
      name: "fax",
      label: "Fax",
      type: "input",
    },
    {
      name: "firstName",
      label: "First Name",
      type: "input",
      props: {
        required: true,
      }
    },
    {
      name: "lastName",
      label: "Last Name",
      type: "input",
      props: {
        required: true,
      }
    },
    {
      name: "mobilePhone",
      label: "Mobile phone",
      type: "input",
    },
    {
      name: "phone",
      label: "Phone",
      type: "input",
    },
    {
      name: "countryId",
      label: "Country",
      type: "select",
      props: {
        required: true,
      },
      options: countries,
    },
    {
      name: "farmCode",
      label: "Farm code",
      type: "input",
    },
    {
      name: "id",
      label: "",
      type: "input",
      props: {
        hidden: true,
      }
    },
  ];

}