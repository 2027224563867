import { useQuery, useMutation } from 'react-query';
import api from 'api';
import { AddNewFarmParams } from 'types';
const {
  getProdplaceByFarm,
  getFarm,
  updateFarm,
  disableFarm,
  reEnableFarm,
  addNewFarm
} = api;

export function useGetFarm(farmId: number) {

  return useQuery(
    ['get-farm', farmId], 
    getFarm,
    {
      enabled: !!farmId,
    }
  );

}

export function useProdplacesByFarm(farmId: number | null) {
  
  return useQuery(
    ['get-prodplaces-by-farm', farmId],
    getProdplaceByFarm,
    {
      enabled: !!farmId,
    }
  );

}

export const useUpdateFarm = () => useMutation((params: AddNewFarmParams) => updateFarm(params));

export const useDisableFarm = () => useMutation((id: number) => disableFarm(id));

export const useReEnableFarm = () => useMutation((id: number) => reEnableFarm(id));

export const useAddNewFarm = () => useMutation((params: AddNewFarmParams) => addNewFarm(params));
