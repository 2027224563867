import React from 'react'
import { Switch, Route } from 'react-router-dom';
import Start from 'pages/start';
import Farms from 'pages/farms';
import Farm from 'pages/farm';
import NoMatch from 'pages/404';
import Species from 'pages/species';
import Projects from 'pages/projects';
import Accounts from 'pages/accounts';
import Account from 'pages/account';
import ServiceGateways from 'pages/serviceGateways';
import ServiceGateway from 'pages/serviceGateway';
import ProductionPlaces from 'pages/productionPlaces';
import ProductionPlace from 'pages/productionPlace';
import Groups from 'pages/groups';
import Scales from 'pages/scales';
import Scale from 'pages/scale';
import ScaleTypes from 'pages/scaleTypes';
import Animals from 'pages/animals';

const routes = (
  <Switch>
    <Route exact path="/" component={Start} /> 
    <Route exact path="/projects" component={Projects} />
    <Route exact path="/accounts" component={Accounts} />
    <Route exact path="/accounts/groups" component={Groups} />
    <Route exact path="/accounts/:accountId" component={Account} />
    <Route exact path="/farms" component={Farms} />
    <Route exact path="/farms/:id" component={Farm} />
    <Route exact path="/production-places" component={ProductionPlaces} />
    <Route exact path="/production-places/:id" component={ProductionPlace} />
    <Route exact path="/species" component={Species} />
    <Route exact path="/service-gateways" component={ServiceGateways} />
    <Route exact path="/service-gateways/:id" component={ServiceGateway} />
    <Route exact path="/scales" component={Scales} />
    <Route exact path="/scales/scale-types" component={ScaleTypes} />
    <Route exact path="/scales/:id" component={Scale} />
    <Route exact path="/animals" component={Animals} />
    <Route component={NoMatch} />
  </Switch>
);

export default routes;