import { useState, useEffect } from 'react';
import { AddNewFarmParams, Farm } from "types";
import { Col, Row, Spin, Statistic, Table, Card, Typography, List, Avatar, Tooltip, Menu, Dropdown, Button, message } from 'antd';
import { Farm as FarmType, ProductionPlace, Country } from 'types';
import { map } from 'lodash';
import { colors } from "utils";
import SingleFarmMap from './components/FarmMap';
import { Link } from "react-router-dom";
import BackLink from "components/BackLink";
import { HiDotsHorizontal } from 'react-icons/hi';
import SimpleForm from 'components/SimpleForm';
import formFields from './farmFields';
import useFarm from './useFarm';

const { Title } = Typography;

export default function SingleFarm () {

  const {
    isFetchingProdplaces,
    prodPlaces,
    hasFetchedProdplaces,
    isUpdatingFarm,
    hasUpdatedFarm,
    updateFarm,
    isDisablingFarm,
    hasDisabledFarm,
    disableFarm,
    isReEnablingFarm,
    hasReEnabledFarm,
    reEnableFarm,
    isFetchingCountries,
    hasFetchedCountries,
    countries,
    isFetchingFarm,
    hasFetchedFarm,
    farm,
  } = useFarm();

  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {

    const key = "status";

    if (isReEnablingFarm) {

       message.loading({
         content: 'Enabling farm',
         key,
       })

    }

    if (isDisablingFarm) {

      message.loading({
        content: 'Disabling farm',
        key,
      })

    }

    if (isUpdatingFarm) {

      message.loading({
        content: 'Updating farm',
        key,
      })

      setIsEditing(false);

    }

    // if (isAddingNewFarm) {

    //   message.loading({
    //     content: 'Adding new service gateway',
    //     key,
    //   })

    // }

    if (hasReEnabledFarm || hasDisabledFarm || hasUpdatedFarm /*|| hasAddedNewFarm*/) {

      message.success({
        content: 'Done!',
        key,
      })
      
      // if (hasAddedNewFarm) {

      //   setAddNew(false)

      // }

    }

  }, [
    isReEnablingFarm,
    isDisablingFarm,
    isUpdatingFarm,
    hasReEnabledFarm,
    hasDisabledFarm,
    hasUpdatedFarm,
    // isAddingNewFarm,
    // hasAddedNewFarm,
  ])

  if (!hasFetchedFarm) {

    return (<></>);

  }

  const {
    address1,
    address2,
    city,
    companyName,
    companyRegistration,
    countryName,
    district,
    email,
    isActive,
    farmCode,
    farmName,
    fax,
    firstName,
    id,
    lastName,
    locationLat,
    locationLong,
    mobilePhone,
    phone,
    statusId,
    serviceGateways,
    zipCode,
  } = farm;

  const onFinishUpdateOrAdd = (action: string, {
    address1,
    address2,
    city,
    district,
    zipCode,
    companyName,
    companyRegistration,
    email,
    fax,
    firstName,
    lastName,
    mobilePhone,
    phone,
    countryId,
    farmCode,
    farmName,
    id
  }: Farm) => {
    
    const formatedData = {
      addressData: {
        address1,
        address2,
        city,
        district,
        zipCode
      },
      contactData: {
        companyName,
        companyRegistration,
        email,
        fax,
        firstName,
        lastName,
        mobilePhone,
        phone
      },
      countryId,
      farmCode,
      farmName,
      id
    }

    updateFarm(formatedData);

  }

  const actionsMenu = (
    <Menu>
      <Menu.Item>
        <a 
          href="#"
          onClick={(e) => {

            e.preventDefault();
            setIsEditing(true);

          }}
        >
          Edit
        </a>
      </Menu.Item>
      <Menu.Item>
        <a 
          href="#"
          onClick={(e) => {
            e.preventDefault();
            isActive ? disableFarm(id) : reEnableFarm(id);

          }}
        >
          {isActive ? 'Deactivate' : 'Activate'}
        </a>
      </Menu.Item>
    </Menu>
  )

  if (isEditing) {

    const formatedCountries = map(countries, ({country, id}) => {
      return {
        label: country,
        value: id,
      }
    })

    return (
      <SimpleForm
        initialValues={farm}
        fields={formFields(formatedCountries)}
        backAction={() => setIsEditing(false)}
        onFinish={(values: Farm) => onFinishUpdateOrAdd('update', values)}
        header="Update farm"
      />
    );

  }

  return (
    <Spin spinning={isFetchingFarm}>
      <Row gutter={[20, 20]}>
        <Col 
          xs={24}
          style={{
            marginTop: 20,
          }}
        >
          <Title level={2}>
            {farmName}
            <Dropdown
              overlay={actionsMenu}
              trigger={['click']}
            >
              <HiDotsHorizontal
                style={{
                  cursor: 'pointer',
                  marginTop: 1,
                  marginLeft: 10,
                  marginBottom: -3,
                  width: 25,
                  height: 25,
                }}
              />
            </Dropdown>
          </Title>
          
        </Col>
        <Col 
          xs={24}
          style={{
            marginTop: -20,
            marginBottom: 20,
          }}
        >
          <Link
            to="/farms"
          >
            <BackLink
              text="Back to all"
            />
          </Link>
        </Col>
        <Col xs={24} md={12}>
          <Card title="General info" bordered={false}>
              <List
                bordered={false}
              >
                <List.Item>
                  <strong>Farm name</strong> <br/> 
                  {farmName}
                </List.Item>
                <List.Item>
                  <strong>Address</strong> <br/>
                  {address1} <br/>
                  {address2} <br/>
                  {city} <br/>
                  {countryName} <br />
                  {district} <br />
                </List.Item>
                <List.Item>
                  <strong>Company</strong> <br/>
                  {companyName} <br/>
                  <a href={`https://www.allabolag.se/what/${companyRegistration.replace("-", "")}/`} target="_blank">{companyRegistration}</a>
                </List.Item>
                <List.Item>
                  <strong>Email</strong>  <br/> 
                  <a href={`mailto:${email}`} target="_blank">{email}</a>
                </List.Item>
                <List.Item>
                  <strong>Phone</strong>  <br/>
                  {mobilePhone} | {phone}
                </List.Item>
                <List.Item>
                  <strong>Location</strong> <br/>
                  <strong>LAT:</strong> {locationLat} | <strong>LNG:</strong> {locationLong}
                </List.Item>
                <List.Item>
                  <strong>Farm ID (Hencols internal)</strong>  <br/>
                  {id}
                </List.Item>
              </List>
          </Card>
          <Card 
            title="Location" 
            style={{
              marginTop: 20,
            }}
            bordered={false}
            extra={<a href={`https://maps.google.com/maps/place/${address1}+${address2}+${zipCode}+${city}+${countryName}`} target="_blank">Map</a>}
          >
            <SingleFarmMap 
              style={{
                marginBottom: 40,
                pointerEvents: 'none',
              }}
              address={`${address1} ${address2} ${zipCode}`}
              lat={locationLat}
              lng={locationLong}
            />
          </Card>
        </Col>
        <Col xs={24} md={12}>
         <Card title="Users (with access)" bordered={false}>
           <Avatar.Group
             maxCount={8}
             size="large"
             maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}
           >
             {map(farm.users, (user) => {

               return (
                 <Tooltip title={user} placement="top" key={user}>
                   <Avatar style={{ backgroundColor: user.indexOf('hencol') > -1 ? '#333' : colors.success }}>{user.substring(0, 2).toUpperCase()}</Avatar>
                 </Tooltip>
               )

             })}
           </Avatar.Group>  
          </Card>
          <Card 
            title="Production places"
            bordered={false}
            style={{
              marginTop: 20,
            }}
          >
            <Row gutter={[20, 20]}>
              {!!prodPlaces.length && prodPlaces.map((prodplace: ProdPlace) => {
                return (
                  
                  <Col key={prodplace.id} span={24}>
                    
                      <Link to={`/production-places/${prodplace.id}`}>{prodplace.code}  (ID: {prodplace.id})</Link> <br/>
                      Name: {prodplace.name} <br/>
                      Species: {prodplace.speciesName}
                    
                  </Col>

                )
              })}
            </Row>
          </Card>
          <Row 
            gutter={[20, 20]}
            style={{
              marginTop: 20,
            }}
          >
            <Col xs={24}>
              <Card title={`Service gateways (${serviceGateways.length})`} bordered={false}>
                <List>
                  {map(serviceGateways.sort(), (serviceGateway) => {

                    return <List.Item key={serviceGateway}>{serviceGateway}</List.Item>

                  })}
                </List>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Spin>
  );

}