
import { useState } from 'react';
import useGroups from './useGroups';
import { Layout, Typography, Row, Col, Button, message} from 'antd';
import AllGroupsTable from './components/AllGroupsTable';
import SimpleForm from 'components/SimpleForm';
import { useEffect } from 'react';

export default function GroupsPage() {

  const [addNew, setAddNew] = useState(false);

  const {
    isFetchingGroups,
    hasFetchedGroups,
    groups,
    refetchGroups,
    isFetchingAccountsByGroups,
    hasFetchedAccountsByGroups,
    accountsByGroups,
    refetchAccountsByGroups,
    groupIdForAccounts,
    setGroupIdForAccounts,
    isAddingNewGroup,
    hasAddedNewGroup,
    addNewGroup,
  } = useGroups();

  useEffect(() => {

    const key = "status";

    if (isAddingNewGroup) {

        message.loading({
          content: 'Adding new group',
          key,
        })

    }

    if (hasAddedNewGroup) {

      message.success({
        content: 'Done!',
        key,
      })
      
      setAddNew(false);

    }

  }, [isAddingNewGroup, hasAddedNewGroup])

  const fields = [
    {
      name: 'groupName', 
      label: 'User group name', 
      type: 'input', 
      props: {
        required: true,
      }
    }
  ];

  const onFinishAddNew = (values: any) => {

    addNewGroup({
      ...values,
    })

  }


  if (addNew) {

    return (
      <SimpleForm 
        backAction={() => setAddNew(false)}
        fields={fields}
        onFinish={onFinishAddNew}
      />
    );

  }

  return (
    <Layout>  
      <Typography.Title>
        User groups
      </Typography.Title>
      <Row gutter={[20, 20]}>
        <Col xs={24}>
          <Button
            type="primary"
            style={{
              marginTop: 10,
              marginBottom: 20,
            }}
            onClick={() => {

              setAddNew(true);
          
            }}
          >
            Add new
          </Button>
        </Col>
        <AllGroupsTable
          groups={groups}
          isFetchingGroups={isFetchingGroups}
          hasFetchedGroups={hasFetchedGroups}
          accountsByGroups={accountsByGroups}
          isFetchingAccountsByGroups={isFetchingAccountsByGroups}
          hasFetchedAccountsByGroups={hasFetchedAccountsByGroups}
          setGroupIdForAccounts={setGroupIdForAccounts}
        />
      </Row>
    </Layout>
  );

}
