
import { useGetFarms } from "hooks/useFarms";
import { 
  useAddServiceGatewayToFarm,
  useDisableServiceGateway,
  useGetServiceGateway,
  useReEnableServiceGateway,
  useUpdateServiceGateway,
  useRemoveServiceGatewayFromFarm,
} from "hooks/useServiceGateways";
import { useParams } from 'react-router-dom';


export default function useServiceGatewayPage () {

  let { id } = useParams();

  const { isLoading: isFetchingServiceGateway, isSuccess: hasFetchedServiceGateway, data: serviceGateway = {}, refetch: refetchServiceGateway } = useGetServiceGateway(id);
  const { isLoading: isDisablingServiceGateway, isSuccess: hasDisabledServiceGateway, mutate: disableServiceGateway } = useDisableServiceGateway();
  const { isLoading: isReEnablingServiceGateway, isSuccess: hasReEnabledServiceGateway, mutate: reEnableServiceGateway } = useReEnableServiceGateway();
  const { isLoading: isUpdatingServiceGateway, isSuccess: hasUpdatedServiceGateway, mutate: updateServiceGateway } = useUpdateServiceGateway();
  const { isLoading: isFetchingFarms, isSuccess: hasFetchedFarms, data: allFarms } = useGetFarms();
  const { isLoading: isAddingServiceGatewayToFarm, isSuccess: hasAddedServiceGatewayToFarm, mutate: addServiceGatewayToFarm } = useAddServiceGatewayToFarm();
  const { isLoading: isRemovingServiceGatewayToFarm, isSuccess: hasRemovedServiceGatewayToFarm, mutate: removeServiceGatewayToFarm } = useRemoveServiceGatewayFromFarm();

  return {
    isFetchingServiceGateway,
    hasFetchedServiceGateway,
    serviceGateway,
    refetchServiceGateway,
    isDisablingServiceGateway,
    hasDisabledServiceGateway,
    disableServiceGateway,
    isReEnablingServiceGateway,
    hasReEnabledServiceGateway,
    reEnableServiceGateway,
    isUpdatingServiceGateway,
    hasUpdatedServiceGateway,
    updateServiceGateway,
    isFetchingFarms,
    hasFetchedFarms,
    allFarms,
    isAddingServiceGatewayToFarm,
    hasAddedServiceGatewayToFarm,
    addServiceGatewayToFarm,
    useAddServiceGatewayToFarm,
    isRemovingServiceGatewayToFarm,
    hasRemovedServiceGatewayToFarm,
    removeServiceGatewayToFarm,
  };

}