
import { useState, useEffect } from 'react';
import useScaleTypesPage from './useScaleTypesPage';
import { Spin, Row, Col, Typography, Button, Input, Table, message } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { ScaleType } from 'types';
import { find, map } from 'lodash';
import SimpleForm from 'components/SimpleForm';
import BackLink from 'components/BackLink';

export default function ScaleTypesPage () {

  const history = useHistory();
  const [isEditing, setIsEditing] = useState<{id: null | number}>({
    id: null,
  })
  const [addNew, setAddNew] = useState(false);

  const {
    isFetchingScaleTypes,
    hasFetchedScaleTypes,
    scaleTypes,
    refetchScaleTypes,
    isUpdatingScaleType,
    hasUpdatedScaleType,
    updateScaleType,
    isAddingScaleType,
    hasAddedScaleType,
    addScaleType,
  } = useScaleTypesPage();


  useEffect(() => {

    const key = "status";

    if (isUpdatingScaleType) {

      message.loading({
        content: 'Updating scale type',
        key,
      })

      setIsEditing({id: null});

    }

    if (isAddingScaleType) {

      message.loading({
        content: 'Adding scale type',
        key,
      })

      setAddNew(false);

    }

    if (hasUpdatedScaleType || hasAddedScaleType) {

      message.success({
        content: 'Done!',
        key,
      })

      refetchScaleTypes();

    }

  }, [
    isUpdatingScaleType,
    isAddingScaleType,
    hasUpdatedScaleType,
    hasAddedScaleType
  ])

  const updateFields = [
    {
      name: "id",
      props: {
        hidden: true,
      }
    },
    {
      name: "name",
      label: "Name",
      type: "input",
      props: {
        required: true,
      }
    },
    {
      name: "brand",
      label: "Brand",
      type: "input",
      props: {
        required: true,
      }
    },
  ]

  const onFinishUpdate = (values: any) => {

    updateScaleType(values);

  }

  if (isEditing.id) {

    const selectedScaleType = find(scaleTypes, (type => type.id === isEditing.id));

    return (
      <SimpleForm
        initialValues={selectedScaleType}
        fields={updateFields}
        backAction={() => setIsEditing({id: null})}
        onFinish={onFinishUpdate}
        header={`Update scale ${selectedScaleType.name}`}
      />
    );

  }

  const addFields = [
    {
      name: "name",
      label: "Name",
      type: "input",
      props: {
        required: true,
      }
    },
    {
      name: "brand",
      label: "Brand",
      type: "input",
      props: {
        required: true,
      }
    },
  ]

  const onFinishAdd = (values: any) => {

    addScaleType(values);

  }

  if (addNew) {

    return (
      <SimpleForm
        fields={addFields}
        backAction={() => setAddNew(false)}
        onFinish={onFinishAdd}
        header={`Add scale type`}
      />
    );

  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Brand',
      dataIndex: 'brand',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text: string, record: ScaleType) => {

        
        return (
          <>
            <Button
              onClick={(e) => {

                e.preventDefault();

                setIsEditing({
                  id: record.id
                });

              }}
            >
              Edit
            </Button>
            {/* <Button 
              danger
              style={{
                marginLeft: 10,
              }}
              onClick={(e) => {

                e.preventDefault();

                setIsEditing({
                  id: record.id
                });

              }}
            >
              Deactivate
            </Button> */}
          </>
        );

      }
    },
  ];

  const scaleTypesWithKey = map(scaleTypes, (type) => {
    return {
      key: type.id,
      ...type,
    }

  })

  return (
    <Spin spinning={isFetchingScaleTypes}>
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <Typography.Title level={1}>
            Scales types
          </Typography.Title>
          <Link 
            to="/scales"
            style={{
              display: 'block',
              marginTop: -10,
            }}
          >
            <BackLink text="Back to scales" />
          </Link>
        </Col>
        <Col xs={24}>
          <Button
            type="primary"
            style={{
              marginTop: 10,
            }}
            onClick={() => {

              setAddNew(true);

            }}
          >
            Add new
          </Button>
        </Col>
        <Col span={24}>
          <Table
            dataSource={scaleTypesWithKey}
            columns={columns}
          />
        </Col>
      </Row>
    </Spin>
  )

}