import React, { useState, useEffect, useMemo } from 'react';
import { Col, Input, Row, Spin, Table, Tag, Typography, Button, message, Switch } from 'antd';
import { map, filter, find, sumBy } from 'lodash';
import { Farm as FarmType, ProdPlace, Country } from 'types';
import ActiveStatusBadge from 'components/ActiveStatusBadge';
import { useHistory } from "react-router-dom";
import { colors, highlightText } from 'utils';
import styled from 'styled-components';
import SimpleForm from 'components/SimpleForm';
import farmFields from 'pages/farm/farmFields';
import TableWithRowHover from 'components/TableWithRowHover';

const { Title } = Typography;

type AllFarmsParams = {
  farms: Array<FarmType>,
  isFetchingFarms: boolean,
  refetchFarms: () => void,
  searchText: string,
  setSearchText: (args0: string) => void,
  onFinishUpdateOrAdd: (action: string, values: FarmType) => void,
  isFetchingCountries: boolean,
  hasFetchedCountries: boolean,
  countries: Array<Country>
  isAddingNewFarm: boolean,
  hasAddedNewFarm: boolean,
}

export default function AllFarms ({
  farms,
  isFetchingFarms,
  refetchFarms,
  searchText,
  setSearchText,
  onFinishUpdateOrAdd,
  isFetchingCountries,
  hasFetchedCountries,
  countries,
  isAddingNewFarm,
  hasAddedNewFarm,
}: AllFarmsParams) {

  const history = useHistory();
  const [addNew, setAddNew] = useState(false);
  const storedOnlyActive = localStorage.getItem('LSP-admin-active-farms-only');
  const [showOnlyActive, setShowOnlyActive] = useState(storedOnlyActive ? JSON.parse(storedOnlyActive) : true);
  const [pageIndex, setPageIndex] = useState(0);
  const storedPagination = localStorage.getItem('LSP-admin-farms-list-pagination');
  const {page: initialPage, pageSize: initialPageSize} = storedPagination ? JSON.parse(storedPagination) : {page: 1, pageSize: 100};

  useEffect(() => {

    if (initialPage > 1) {

      setPageIndex(initialPageSize * (initialPage - 1));

    }

  }, []);

  useEffect(() => {

    const key = "status";

    if (isAddingNewFarm) {

       message.loading({
         content: 'Adding new farm',
         key,
       })

    }

    if (hasAddedNewFarm) {

      message.success({
        content: 'Done!',
        key,
      })
      
      refetchFarms();
      setAddNew(false)

    }

  }, [
    isAddingNewFarm,
    hasAddedNewFarm,
  ])

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    const text = event.target.value;
    const searchText = text.length >= 1 ? text : '';
    
    setSearchText(searchText);

  }

  const allFarms = showOnlyActive ? filter(farms, (farm: FarmType) => farm.isActive) : farms;

  const filteredFarms: Array<FarmType> = filter(allFarms, (farm: FarmType) => {

    const { farmName, companyName, address1, address2, city, district, id } = farm;

    const search = searchText.toLowerCase();
    const address = `${address1} ${address2} ${city} ${district}`.toLowerCase();
    
    return farmName.toLowerCase().indexOf(search) > -1 || companyName.toLowerCase().indexOf(search) > -1 || address.indexOf(search) > -1 || `${id}`.indexOf(search) > -1;

  });

  const dataSource = map(filteredFarms, (farm: FarmType) => {

    return {
      ...farm,
      key: farm.farmName,
    };

  });

  const columns = [
    {
      title: 'Row',
      key: 'row',
      render: (text: string, record: FarmType, i: number) => {

        return pageIndex + (i + 1);

      },
      width: 30,
    },
    {
      title: 'ID',
      dataIndex: 'id',
      render: (text: string) => highlightText(text, searchText),
      sorter:(a: FarmType, b: FarmType) => (a.id - b.id),
      width: 40,
    },
    {
      title: `Name`,
      dataIndex: 'farmName',
      key: 'name',
      sorter: {
        compare: (a: FarmType, b: FarmType) => {
          
          if (a.farmName < b.farmName) {
            return -1;
          }
          if (a.farmName > b.farmName) {
            return 1;
          }
        
          // names must be equal
          return 0;
        
        },
      },
      render: (text: string) => highlightText(text, searchText),
      width: 200,
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      render: (text: boolean) => <ActiveStatusBadge isActive={text} />,
      sorter:(a: FarmType, b: FarmType) => (a.isActive - b.isActive),
      // render: (text: number) => {

      //   switch (text) {
            
      //     case 20:
      //       return <Tag color={colors.success}>Active</Tag>;
            
      //     default:
      //       return `Status ${text} not specified`;

      //   }

      // },
      width: 110,
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      render: (value: string, record: FarmType) => {

        const { address1, address2, city, district } = record;
        const allText = `${address1} ${address2} ${city} ${district}`;

        return highlightText(allText, searchText);
        
      },
      width: 300,
    },
    {
      title: 'Company name',
      dataIndex: 'companyName',
      key: 'companyName',
      sorter: {
        compare: (a: FarmType, b: FarmType) => {
          
          if (a.companyName < b.companyName) {
            return -1;
          }
          if (a.companyName > b.companyName) {
            return 1;
          }
        
          // names must be equal
          return 0;
        
        },
      },
      render: (text: string) => highlightText(text, searchText),
      width: 250,
    },
  ];

  const totalWidth = useMemo(() => sumBy(map(columns, (column) => column.width)), columns);

  if (addNew) {

    const formatedCountries = map(countries, ({country, id}) => {
      return {
        label: country,
        value: id,
      }
    });

    return (
      <SimpleForm 
        fields={farmFields(formatedCountries)}
        onFinish={(values: FarmType) => onFinishUpdateOrAdd('add', values)}
        backAction={() => setAddNew(false)}
        header="Add new farm"
      />
    );

  }



  return (
    <Spin spinning={isFetchingFarms}>
      <Row gutter={[20, 20]}>
        <Col xs={24}>
          <Button
            type="primary"
            style={{
              marginTop: 10,
            }}
            onClick={() => {

              setAddNew(true);

            }}
          >
            Add new
          </Button>
        </Col>
        <Col span={12}>
          <Input
            onChange={onChange}
            placeholder={'Filter by any value'}
          />
          <Title 
            level={5} 
            style={{
              marginTop: 20,
            }}
          >Showing: {dataSource.length}/{farms.length}</Title>
        </Col>
        <Col span={24}>
          <strong
            style={{
              display: 'block',
              marginBottom: 5,
            }}
          >Only active</strong>
          <Switch 
            checked={showOnlyActive}
            onChange={(value) => {

              localStorage.setItem('LSP-admin-active-farms-only', JSON.stringify(value));
              setShowOnlyActive(value)
            
            }}
          />
        </Col>
        <Col span={24}>
          <TableWithRowHover
            dataSource={dataSource}
            columns={columns}
            onRow={(record: FarmType) => {
              
              return {
                onClick: () => history.push(`/farms/${record.id}`)
              }

            }}
            scroll={{
              x: totalWidth,
            }}
            pagination={{
              defaultCurrent: initialPage,
              defaultPageSize: initialPageSize,
              pageSizeOptions: ['20', '50', '100', '200'],
              position: ["topCenter", "bottomCenter"],
              onChange: (page: number, pageSize: number) => {

                localStorage.setItem('LSP-admin-farms-list-pagination', JSON.stringify({page, pageSize}));
    
                if (page > 1) {
    
                  setPageIndex(pageSize * (page - 1));
            
                } else {
    
                  setPageIndex(0);
    
                }
    
                return;
    
              }
            }}
            
          />
        </Col>
      </Row>
    </Spin>
  );

}