
import { useState, useEffect } from 'react';
import useScalePage from './useScalePage';
import { Row, Col, Spin, Typography, Dropdown, Card, List, Menu, message } from 'antd';
import { HiDotsHorizontal } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import BackLink from 'components/BackLink';
import ActiveStatusBadge from 'components/ActiveStatusBadge';
import ListItemWithTitle from 'components/ListItemWithTitle';
import SimpleForm from 'components/SimpleForm';
import { Scale } from 'types';
import { map } from 'lodash';
import scaleFields from './scaleFields';
import { weightingTypes } from 'utils';
import moment from 'moment';

export default function ScalePage () {

  const {
    isFetchingScale,
    hasFetchedScale,
    scale,
    refetchScale,
    isDisablingScale,
    hasDisabledScale,
    disableScale,
    isReEnablingScale,
    hasReEnabledScale,
    reEnableScale,
    isUpdatingScale,
    hasUpdatedScale,
    updateScale,
    isFetchingScaleTypes,
    hasFetchedScaleTypes,
    scaleTypes,
    isFetchingServiceGateways,
    hasFetchedServiceGateways,
    serviceGateways,
  } = useScalePage();

  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {

    const key = "status";

    if (isReEnablingScale) {

       message.loading({
         content: 'Enabling scale',
         key,
       })

    }

    if (isDisablingScale) {

      message.loading({
        content: 'Disabling scale',
        key,
      })

    }

    if (isUpdatingScale) {

      message.loading({
        content: 'Updating scale',
        key,
      })

      setIsEditing(false);

    }

    if (hasReEnabledScale || hasDisabledScale || hasUpdatedScale) {

      message.success({
        content: 'Done!',
        key,
      })

      refetchScale();

    }

  }, [
    isReEnablingScale,
    isDisablingScale,
    isUpdatingScale,
    hasReEnabledScale,
    hasDisabledScale,
    hasUpdatedScale,
  ])

  const onFinishUpdate = ({
    displayName,
    id,
    isAvailable,
    name,
    notes,
    scaleTypeId,
    serialNumber,
    serviceGatewayId,
    weightingType,
  }: Scale) => {
    
    const formatedData = {
      displayName,
      id,
      isAvailable,
      name,
      notes,
      scaleTypeId,
      serialNumber,
      serviceGatewayId,
      weightingType,
    }

    updateScale(formatedData);

  }

  const {
    displayName,
    id,
    isActive,
    isAvailable,
    name,
    notes,
    scaleBrand,
    scaleType,
    scaleTypeId,
    serialNumber,
    serviceGatewayId,
    serviceGatewayName,
    lastContact,
  } = scale;

  const actionsMenu = (
    <Menu>
      <Menu.Item>
        <a 
          href="#"
          onClick={(e) => {

            e.preventDefault();
            setIsEditing(true);

          }}
        >
          Edit
        </a>
      </Menu.Item>
      <Menu.Item>
        <a 
          href="#"
          onClick={(e) => {

            e.preventDefault();

            isActive ? disableScale(id) : reEnableScale(id);

          }}
        >
          {isActive ? 'Deactivate' : 'Activate'}
        </a>
      </Menu.Item>
    </Menu>
  )

  if (isEditing) {

    const formatedScaleTypes = map(scaleTypes, ({name, id}) => {
      return {
        label: name,
        value: id,
      }
    })

    const formatedWeightingTypes = map(weightingTypes, (item) => {
      return {
        label: item,
        value: item,
      }
    })

    const formatedServiceGateways = map(serviceGateways, ({sgId, id}) => {
      return {
        label: sgId,
        value: id,
      }
    })

    return (
      <SimpleForm
        initialValues={scale}
        fields={scaleFields(formatedScaleTypes, formatedWeightingTypes, formatedServiceGateways)}
        backAction={() => setIsEditing(false)}
        onFinish={onFinishUpdate}
        header={`Update scale ${name}`}
      />
    );

  }

  return (
    <Spin spinning={isFetchingScale}>
      <Row gutter={[20, 20]}>
        <Col 
          xs={24}
          style={{
            marginTop: 20,
          }}
        >
          <Typography.Title level={2}>
            {name}
            <Dropdown
              overlay={actionsMenu}
              trigger={['click']}
            >
              <HiDotsHorizontal
                style={{
                  cursor: 'pointer',
                  marginTop: 1,
                  marginLeft: 10,
                  marginBottom: -3,
                  width: 25,
                  height: 25,
                }}
              />
            </Dropdown>
          </Typography.Title>  
        </Col>
        <Col 
          xs={24}
          style={{
            marginTop: -20,
            marginBottom: 20,
          }}
        >
          <Link
            to="/scales"
          >
            <BackLink
              text="Back to all"
            />
          </Link>
        </Col>
        <Col xs={24} md={12}>
          <Card title="General info" bordered={false}>
              <List
                bordered={false}
              >
                <ListItemWithTitle 
                  title={'Name'}
                  items={[
                    name,
                  ]}
                />
                <ListItemWithTitle 
                  title={'Status'}
                  items={[
                    <ActiveStatusBadge isActive={isActive} />,
                  ]}
                />
                <ListItemWithTitle 
                  title={'Last contact'}
                  items={[
                    moment(lastContact).fromNow(),
                    moment(lastContact).format('YYYY-MM-DD hh:mm:ss'),
                  ]}
                />
                <ListItemWithTitle 
                  title={'Service gateway'}
                  items={[
                    <Link to={`/service-gateways/${serviceGatewayId}`}>{serviceGatewayName}</Link>,
                  ]}
                />
                <ListItemWithTitle 
                  title={'Serial number'}
                  items={[
                    serialNumber,
                  ]}
                /> 
                <ListItemWithTitle 
                  title={'Scale'}
                  items={[
                    `Brand: ${scaleBrand}`,
                    `Type: ${scaleType}`,
                  ]}
                /> 
                <ListItemWithTitle 
                  title={'Notes'}
                  items={[
                    notes,
                  ]}
                />
              </List>
          </Card>
        </Col>
      </Row>
    </Spin>
  );

}