import { useEffect, useState } from 'react';
import { Scale, ScaleType, ServiceGateway, UpdateScaleParams, AddScaleParams } from 'types';
import { UseMutateFunction } from 'react-query';
import { Row, Col, Card, Table, Typography, Tag, Spin, Input, Menu, Form, Select, Button, Switch, message } from 'antd';
import { map, filter, sumBy} from 'lodash';
import { useMemo } from 'react';
import { colors, highlightText } from 'utils';
import { HiDotsHorizontal } from 'react-icons/hi';
import BackLink from 'components/BackLink';
import SimpleForm from 'components/SimpleForm';
import ActiveStatusBadge from 'components/ActiveStatusBadge';
import { useHistory } from 'react-router-dom';
import useScalesPage from './useScalesPage';
import TableWithRowHover from 'components/TableWithRowHover';
import { Link } from 'react-router-dom';
import ForwardLink from 'components/ForwardLink';

const { TextArea } = Input;

export default function ScalesPage () {

  const {
    isFetchingScales,
    scales,
    refetchScales,
    hasFetchedScales,
    useGetScaleTypes,
    isFetchingScaleTypes,
    scaleTypes,
    hasFetchedScaleTypes,
    isUpdatingScale,
    hasUpdatedScale,
    updateScale,
    isDisablingScale,
    hasDisabledScale,
    disableScale,
    isReEnableScale,
    hasReEnabledScale,
    reEnableScale,
    isAddingNewScale,
    hasAddedNewScale,
    addNewScale,
    isFetchingServiceGateways,
    serviceGateways,
    hasFetchedServiceGateways,
    refetchServiceGateways,
  } = useScalesPage();

  const history = useHistory();
  const [searchText, setSearchText] = useState('');
  const [addNew, setAddNew] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const storedPagination = localStorage.getItem('LSP-admin-scales-list-pagination');
  const {page: initialPage, pageSize: initialPageSize} = storedPagination ? JSON.parse(storedPagination) : {page: 1, pageSize: 100};

  useEffect(() => {

    if (initialPage > 1) {

      setPageIndex(initialPageSize * (initialPage - 1));

    }

  }, []);

  useEffect(() => {

    const key = "status";

    if (isReEnableScale) {

       message.loading({
         content: 'Enabling scale',
         key,
       })

    }

    if (isDisablingScale) {

      message.loading({
        content: 'Disabling scale',
        key,
      })

    }

    if (isAddingNewScale) {

      message.loading({
        content: 'Adding new scale',
        key,
      })

    }

    if (hasReEnabledScale || hasDisabledScale || hasAddedNewScale) {

      refetchScales();

      message.success({
        content: 'Done!',
        key,
      })

      if (hasAddedNewScale) {

        setAddNew(false);

      }

    }

  }, [
    isReEnableScale,
    hasReEnabledScale,
    isDisablingScale,
    hasDisabledScale,
    isAddingNewScale,
    hasAddedNewScale,
  ])

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    const text = event.target.value;
    const searchText = text.length >= 1 ? text : '';
    
    setSearchText(searchText);

  }

  const scalesWithKey = map(scales, (scale) => {
    
    return {
      key: scale.id,
      ...scale,
    }

  });

  const dataSource: Array<Scale> = filter(scalesWithKey, (scale: Scale) => {

    const { name, serviceGatewayName, scaleType, notes } = scale;

    const search = searchText.toLowerCase();
    
    return name?.toLowerCase().indexOf(search) > -1 || serviceGatewayName?.toLowerCase().indexOf(search) > -1 || scaleType?.toLowerCase().indexOf(search) > -1 || notes?.toLowerCase().indexOf(search) > -1;

  });

  const renderWeightingScenario = (value: string) => {

    switch (value) {
      case 'manyerrors':
        return 'Automatic';
        
      case 'errorfree':
        return 'Manual';

      case 'errorfree':
        return 'error free';

      default:
        return 'Not set';
    }

  }

  const columns = [
    {
      title: 'Row',
      key: 'row',
      render: (text: string, record: Scale, i: number) => {

        return pageIndex + (i + 1);

      },
      width: 30,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text: string) => highlightText(text, searchText),
      sorter:(a: Scale, b: Scale) => a.name.localeCompare(b.name),
      width: 170,
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      key: 'status',
      render: (text: boolean) => <ActiveStatusBadge isActive={text} />,
      sorter:(a: Scale, b: Scale) => (a.isActive - b.isActive),
      width: 110,
    },
    {
      title: 'Service gateway',
      dataIndex: 'serviceGatewayName',
      key: 'serviceGateway',
      render: (text: string) => highlightText(text, searchText),
      sorter:(a: Scale, b: Scale) => a.serviceGatewayName.localeCompare(b.serviceGatewayName),
      width: 180,
    },  
    {
      title: 'Weighting type',
      dataIndex: 'weightingType',
      key: 'weightingType',
      // render: (text: string) => renderWeightingScenario(text),
      sorter:(a: Scale, b: Scale) => a.weightingType.localeCompare(b.weightingType),
      width: 150,
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      key: 'notes',
      render: (text: string) => highlightText(text, searchText),
      width: 350,
    },
    {
      title: 'Scale type',
      dataIndex: 'scaleType',
      key: 'scaleType',
      render: (text: string) => highlightText(text, searchText),
      sorter:(a: Scale, b: Scale) => a.scaleType.localeCompare(b.scaleType),
      width: 120,
    },
  ];

  const onFinish = (values: any) => {

   updateScale(values);
    
  }

  const scaleTypeOptions = map(scaleTypes, ({id, name}: ScaleType) => {
      
    return {
      value: id,
      label: name,
    }

  });

  const serviceGatewayOptions = map(serviceGateways, ({id, sgId}: ServiceGateway) => {

    return {
      label: sgId,
      value: id,
    }

  });

  const onFinishAddNew = (values: any) => {

    addNewScale(values);

  }

  if (addNew) {

    const fields = [
      {
        name: "displayName",
        label: "Display name",
        type: "input",
        props: {
          required: true,
        }
      },
      {
        name: "name",
        label: "Name (WS ID)",
        type: 'input',
        props: {
          required: true,
        }
      },
      {
        name: "isAvailable",
        label: "Is available?",
        type: 'switch',
      },
      {
        name: "weightingType",
        label: "Weighting type",
        type: 'select',
        options: [
          {
            value: 'errorfree',
            label: 'errorfree',
          },
          {
            value: 'someerrors',
            label: 'someerrors',
          },
          {
            value: 'manyerrors',
            label: 'manyerrors',
          }
        ],
        props: {
          required: true,
        }
      },
      {
        name: "scaleTypeId",
        label: "Scale type",
        type: 'select',
        options: scaleTypeOptions,
        props: {
          required: true,
        }
      },
      {
        name: "serviceGatewayId",
        label: "Service gateway",
        type: 'select',
        options: serviceGatewayOptions,
      },
      {
        name: "notes",
        label: "Notes",
        type: 'textarea',
      },
      {
        name: "serialNumber",
        label: "Serial number",
        type: 'input',
      }
    ]

    return (
      <SimpleForm
        fields={fields}
        onFinish={onFinishAddNew}
        backAction={() => setAddNew(false)}
        header="Add new scale"
      />
    );

  }

  const tableWidth = sumBy(map(columns, (column) => column.width)) 

  return (
    <Spin spinning={isFetchingScales}>
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <Typography.Title level={1}>
            Scales
          </Typography.Title>
          <Link
            to="/scales/scale-types"
            style={{
              display: 'block',
              marginTop: -10,
              marginBottom: 10,
            }}
          >
            <ForwardLink text="Scale types" />
          </Link>
          <Button
            type="primary"
            style={{
              marginTop: 10,
            }}
            onClick={() => {

              setAddNew(true);

            }}
          >
            Add new
          </Button>
        </Col>
        <Col
          span={12}
          style={{
            maxWidth: 400
          }}
        >
          <strong>Filter</strong>
          <Input
            onChange={onChange}
            placeholder={'Filter by Name, Service gateway name, Scale type or Notes'}
          />
          <Typography.Title 
            level={5} 
            style={{
              marginTop: 20,
            }}
          >Showing: {dataSource.length}/{scalesWithKey.length}</Typography.Title>
        </Col>
        <Col span={24}>
          <TableWithRowHover
            dataSource={dataSource}
            columns={columns}
            onRow={(record: Scale) => {
              
              return {
                onClick: () => history.push(`/scales/${record.id}`)
              }

            }}
            scroll={{
              x: tableWidth,
            }}
            pagination={{
              defaultCurrent: initialPage,
              defaultPageSize: initialPageSize,
              pageSizeOptions: ['20', '50', '100', '200'],
              position: ["topCenter", "bottomCenter"],
              onChange: (page: number, pageSize: number) => {

                localStorage.setItem('LSP-admin-scales-list-pagination', JSON.stringify({page, pageSize}));
    
                if (page > 1) {
    
                  setPageIndex(pageSize * (page - 1));
            
                } else {
    
                  setPageIndex(0);
    
                }
    
                return;
    
              }
            }}
          />
        </Col>
      </Row>
    </Spin>
  )

}