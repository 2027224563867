import { useState, useMemo } from 'react';
import { Scale, AnimalWeightSpanParams } from 'types';
import moment from 'moment';
import { UseMutateFunction } from 'react-query';
import { Card, DatePicker, Row, Col, Typography, Input, Form, Select, Button, Table, Tooltip } from 'antd';
import { map, sumBy } from 'lodash';

type WeightsInDateSpanParams = {
  isFetchingWeights: boolean,
  hasFetchedWeights: boolean,
  weights: Array<string>,
  fetchWeights: UseMutateFunction<any, unknown, AnimalWeightSpanParams, unknown>,
  isFetchingScales: boolean,
  hasFetchedScales: boolean,
  scales: Array<Scale>,
}

export default function WeightsInDateSpan ({
  isFetchingWeights,
  hasFetchedWeights,
  weights,
  fetchWeights,
  isFetchingScales,
  hasFetchedScales,
  scales,
}: WeightsInDateSpanParams) {

  const [form] = Form.useForm();
  const [formIsOk, setFormIsOk] = useState(false);

  const disabledDate = (current: Moment) => {
    // Can not select days before today and today
    return current && current > moment().endOf('day');
  }

  const onFinish = (values: any) => {

    const {
      animalId,
      eid,
      officialNumber,
      dates,
      scaleId,
    } = values;

    console.log(values);

    const formatedValues = {
      dateFrom: (dates && dates[0]) ? moment(dates[0]).format('YYYY-MM-DD') : null,
      dateTo: (dates && dates[1]) ? moment(dates[1]).format('YYYY-MM-DD') : null,
      officialNumber,
      animalId,
      eid,
      scaleId,
    }

    fetchWeights(formatedValues);

  }

  const onFormChange = () => {

    const {
      animalId,
      eid,
      officialNumber,
      dates,
    } = form.getFieldsValue();

    console.log('dates');

    setFormIsOk(dates);

  }

  const renderWeightingType = (type: 'manyerrors' | 'errorfree' | 'someerrors' | 'manuallyentered') => {

    let string;

    switch (type) {
      case 'manyerrors':
      case 'someerrors':
        string = 'Automatic';
        break;
      
      case 'errorfree':
      case 'manuallyentered':
        string = 'Manual';

      default:
        string = '';
        break;
    }

    return string;

  }

  const dataSource = map(weights, (weight, i) => {

    return {
      key: i,
      ...weight,
    }

  })

  const columns = [
    {
      title: "Animal",
      dataIndex: "animalOfficialNumber",
      sorter:(a: any, b: any) => (a.animalOfficialNumber && b.animalOfficialNumber) && a.animalOfficialNumber.localeCompare(b.animalOfficialNumber),
      width: 200,
    },
    {
      title: "EID",
      dataIndex: "eid",
      sorter:(a: any, b: any) => a.eid.localeCompare(b.eid),
      width: 180,
    },
    {
      title: "Weight",
      key: "Weight",
      render: (record: any) =>  `${record.weight}kg`,
      sorter:(a: any, b: any) => a.eid - b.eid,
      width: 110,
    },
    // {
    //   title: "Weighting Type",
    //   key: "weightingType",
    //   render: (record: any) =>  renderWeightingType(record.weightingType),
    // },
    {
      title: "Dates",
      key: "dates",
      render: (record: any) =>  {

        return (
          <span>
            {moment(record.dateCreated).format('YYYY-MM-DD HH:mm')} <br />
            <Tooltip title={"How long after the registered was made was it sent"}><span style={{fontSize: 12,}}>{moment(record.dateInserted).diff(moment(record.dateCreated), 'minutes')} min later</span></Tooltip>
          </span> 
        );

      },
      sorter:(a: any, b: any) => moment(a.dateCreated).unix() - moment(b.dateCreated).unix(),
      width: 200,
    },
    {
      title: "Weighting Type",
      key: "weightingType",
      render: (record: any) =>  renderWeightingType(record.weightingType),
      sorter:(a: any, b: any) => a.weightingType.localeCompare(b.weightingType),
      width: 160,
    },
    {
      title: "Scale",
      dataIndex: "scaleName", 
      sorter:(a: any, b: any) => a.scaleName.localeCompare(b.scaleName),
      width: 200,
    },
    {
      title: 'Service gateway',
      dataIndex: 'serviceGatewayName',
      sorter:(a: any, b: any) => a.serviceGatewayName.localeCompare(b.serviceGatewayName),
      width: 200,
    }

  ];

  const totalWidth = useMemo(() => sumBy(map(columns, (column) => column.width)), columns);

  return (
    <Card>
      <Typography.Title level={4}>
        Animals weight readings
      </Typography.Title>
      <Form
        form={form}
        layout={'vertical'}
        style={{
          width: '100%',
        }}
        onValuesChange={onFormChange}
        onFinish={onFinish}
      >
        <Row gutter={[20, 20]}>
          <Col xs={24} md={12}>
            <Form.Item
              name="officialNumber"
              labelCol={{span: 24}}
              label={'ID'}
            >
              <Input 
                placeholder={'SE12345678'}
              />
            </Form.Item>
            <Form.Item
              name="animalId"
              labelCol={{span: 24}}
              label={'ID (internal)'}
            >
              <Input 
                placeholder={'123123'}
              />
            </Form.Item>
            <Form.Item
              name="eid"
              label={'EID'}
            >
              <Input 
                placeholder={'752000000000000'}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="dates"
              label={'Dates'}
              required={true}
            >
              <DatePicker.RangePicker 
                disabledDate={disabledDate}
                // allowEmpty={[false, true]}
              />
            </Form.Item>
            <Form.Item
              name="scaleId"
              label={'Scale'}
            >
              <Select 
                showSearch
                options={map(scales, (scale) => {
                  
                  return {
                    label: `${scale.name} - ${scale.serviceGatewayName}`,
                    value: scale.id,
                  }

                })}
                filterOption={(input, option) => {
                  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
                filterSort={(optionA, optionB) =>
                 optionA.label.toLowerCase().localeCompare(optionB.label.toLowerCase())
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item>
              <Button 
                type="primary"
                htmlType="submit"
                disabled={!formIsOk}
              >
                Load
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <strong>
              Showing {dataSource.length} registrations
            </strong>
            <Table
              loading={isFetchingWeights}
              dataSource={dataSource}
              columns={columns}
              scroll={{
                x: totalWidth,
              }}
              pagination={{
                defaultPageSize: 100,
                pageSizeOptions: ['20', '50', '100', '200'],
                position: ["topCenter", "bottomCenter"],
              }}
            />
          </Col>
        </Row>
      </Form>
    </Card>
  );

}
