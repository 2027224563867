import { useState, useRef, useEffect } from 'react';
import mapboxgl from 'mapbox-gl';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import styled from 'styled-components';
import "mapbox-gl/dist/mapbox-gl.css"

mapboxgl.accessToken = 'pk.eyJ1IjoiaGVuY29sIiwiYSI6ImNrc3VlYXgzYTBiencycHB1ZmkzMWoyc3UifQ.S43TYyI_wvywKS6qfuHm0Q';


const Map = styled('div')`
  width: 100%;
  height: 300px;
  overflow: hidden;
  pointerEvents: none;
  
  .mapboxgl-ctrl-logo,
  .mapboxgl-ctrl-attrib-inner {
    display: none !important;
  }

  .mapboxgl-marker {
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    border-radius: 50%;
    background-color: #334bfa;
    border: 3px solid #fff;
    box-shadow: 0 2px 8px 0 rgb(0 0 0 / 20%);

    svg {
      display: none;
    }

  }

`

const Marker = styled('div')`
  display: none;
`;

type SingleFarmMapParams = {
  lat: string | number,
  lng: string | number,
  address: string,
  style: object,
}

export default function SingleFarmMap ({
  address,
  style,
}: SingleFarmMapParams) {

  const [location, setLocation] = useState([]);
  const [zoom, setZoom] = useState(4);
  const mapContainer = useRef(null);
  const markerContainer = useRef(null);
  let marker = useRef(null)
  let map = useRef(null);
  
  useEffect(() => {

    getLocation();
  
  }, []);

  useEffect(() => {

    if (map.current) {

      return;

    }
    
    initMap();
     
  }, [location])

  const initMap = () => {

    if (!map.current && mapContainer.current && location.length) {

      const coordinates = [
        location[0].x,
        location[0].y
      ];

      map = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/hencol/ckszrtwkva9p118mrazhsiusl',
        scrollZoom: false,
        doubleClickZoom: false,
        dragRotate: false,
        dragPan: false,
        center: coordinates,
        zoom: zoom,
      });    

      marker = new mapboxgl.Marker(markerContainer).setLngLat(coordinates).addTo(map);

    }

  }

  const getLocation = async () => {

    // setup
    const provider = new OpenStreetMapProvider();

    // search
    const location = await provider.search({ query: address });

    if (location.length) {

      setLocation(location);
 
    }
    
    return;
  }

  if (!location.length) {

    return null;

  }

  return (
    <Map 
      ref={mapContainer}
      className="map-container"
    >
      <Marker 
        ref={markerContainer}
      />
    </Map>
  );
}