
import { useState } from 'react';
import { useDisableFarm, useProdplacesByFarm, useUpdateFarm, useReEnableFarm, useAddNewFarm, useGetFarm } from '../../hooks/useFarm';
import { useGetCountries } from 'hooks/useCountries';
import useUrlParam from 'hooks/useUrlParam';
import { useParams } from 'react-router-dom';

export default function useFarmsPage () {

  let { id } = useParams();
  const { isLoading: isFetchingProdplaces, data: prodPlaces = {}, isSuccess: hasFetchedProdplaces } = useProdplacesByFarm(id);
  const { isLoading: isUpdatingFarm, isSuccess: hasUpdatedFarm, mutate: updateFarm } = useUpdateFarm();
  const { isLoading: isAddingNewFarm, isSuccess: hasAddedNewFarm, mutate: addNewFarm } = useAddNewFarm();
  const { isLoading: isDisablingFarm, isSuccess: hasDisabledFarm, mutate: disableFarm } = useDisableFarm();
  const { isLoading: isReEnablingFarm, isSuccess: hasReEnabledFarm, mutate: reEnableFarm } = useReEnableFarm();
  const { isLoading: isFetchingCountries, isSuccess: hasFetchedCountries, data: countries = {} } = useGetCountries();
  const { isLoading: isFetchingFarm, isSuccess: hasFetchedFarm, data: farm = {}} = useGetFarm(id);

  return {
    isFetchingProdplaces,
    prodPlaces,
    hasFetchedProdplaces,
    isUpdatingFarm,
    hasUpdatedFarm,
    updateFarm,
    isDisablingFarm,
    hasDisabledFarm,
    disableFarm,
    isReEnablingFarm,
    hasReEnabledFarm,
    reEnableFarm,
    isFetchingCountries,
    hasFetchedCountries,
    countries,
    isAddingNewFarm,
    hasAddedNewFarm,
    addNewFarm,
    isFetchingFarm,
    hasFetchedFarm,
    farm,
  }

}
