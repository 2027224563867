import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosError,
} from 'axios';
import config from 'config'

import { notification } from 'antd';
import { MdErrorOutline } from 'react-icons/md';

const checkStatus = (error: any) => {

  const { response } = error;

  if (response?.status === 400) {

    if (response?.data?.errors) {
    
      notification.open({
        message: `ERROR: ${response.status}`,
        description: `${response?.data?.errors?.errors || response?.data?.errors}`,
        type: 'error',
        closeIcon: true,
        placement: 'bottomRight',
        style: {
          borderTop: '3px solid red',
        }
      });

    }

  }

  if (response.status === 401 && response?.data.status === "WRONG_CREDENTIALS") {

    localStorage.removeItem('hencol-admin-session');
    document.location.reload();

  }

}

export default function request(service: AxiosInstance) {

  const baseUrl = config.LSP.url;

  return (url: string, options: AxiosRequestConfig) => {
    return service(`${baseUrl}${url}`, options).catch(checkStatus);
  };

}