import { useEffect } from "react";
import { message } from 'antd';
import useHandleProductionPlaceForm from "./useHandleProductionPlaceForm";
import SimpleForm from 'components/SimpleForm';
import { map } from 'lodash';
import productionPlaceFields from './productionPlaceFields';
import { ProductionPlace } from 'types';

type HandleProductionPlaceFormParams = {
  initialValues: any,
  backAction: () => void,
  action: 'add' | 'update',
  id: number | null,
  refetch: () => void,
}

export default function HandleProductionPlaceForm ({
  initialValues,
  backAction,
  action,
  id,
  refetch,
}: HandleProductionPlaceFormParams) {

  const {
    isUpdatingProductionPlace,
    hasUpdatedProductionPlace,
    updateProductionPlace,
    isAddingNewProductionPlace,
    hasAddedNewProductionPlace,
    addNewProductionPlace,
    isFetchingFarms,
    hasFetchedFarms,
    farms,
    isFetchingProductionOrientations,
    hasFetchedProductionOrientations,
    productionOrientations,
    isFetchingSpecies,
    hasFetchedSpecies,
    species,
  } = useHandleProductionPlaceForm();

  useEffect(() => {

    const key = "status";
    
    if (isUpdatingProductionPlace || isAddingNewProductionPlace) {

      const text = isAddingNewProductionPlace ? 'Adding new production place' : 'Updating Production place';

      message.loading({
        content: text,
        key,
      })

    }

    if (hasUpdatedProductionPlace || hasAddedNewProductionPlace) {

      message.success({
        content: 'Done!',
        key,
      })

      refetch();
      backAction();
      
    }

  }, [
    isUpdatingProductionPlace,
    hasUpdatedProductionPlace,
    isAddingNewProductionPlace,
    hasAddedNewProductionPlace,
  ]);

  const onFinish = (values: ProductionPlace) => {

    const { 
      code,
      daysLimit,
      name,
      productionOrientationId,
      farmId,
      speciesId,
    } = values;

    let formatedValues;

    switch (action) {
      case 'update':
        updateProductionPlace({
          id,
          code,
          daysLimit,
          name: code,
          productionOrientationId,
          farmId,
          speciesId,
        });
        break;

      case 'add':
        addNewProductionPlace({
          code,
          daysLimit,
          name: code,
          farmId,
          productionOrientationId,
          speciesId,
        });
        break;
    
      default:
        console.error('Submit action missing');
        break;
    }

  }

  const selectValues = (array: Array<any>, label: string, value: string) => {

    return map(array, (item) => {
      
      return {
        label: item[label],
        value: item[value],
      };

    });

  }

  const formatedFarms = selectValues(farms, 'farmName', 'id');
  const formatedProductionOrientations = selectValues(productionOrientations, 'name', 'id');
  const formatedSpecies = selectValues(species, 'name', 'id');
  
  return (    
    <SimpleForm
      initialValues={initialValues}
      fields={productionPlaceFields(formatedFarms, formatedProductionOrientations, formatedSpecies)}
      backAction={() => backAction()}
      onFinish={onFinish}
      header={action == 'update' ? 'Update' : 'Add new'}
    />
  )
}