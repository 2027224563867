import { 
  useGetProductionPlace,
  useDisableProductionPlace,
  useReEnableProductionPlace,
} from 'hooks/useProdplaces';
import { useParams } from 'react-router-dom';
import { useGetTotalAnimalsByProductionPlace } from 'hooks/useAnimals';

export default function useProductionPlacePage() {

  let { id } = useParams();

  const { isLoading: isFetchingProductionPlace, data: productionPlace = {}, isSuccess: hasFetchedProductionPlace, refetch: refetchProductionPlace } = useGetProductionPlace(id);
  const { isLoading: isDisablingProductionPlace, isSuccess: hasDisabledProductionPlace, mutate: disableProductionPlace } = useDisableProductionPlace();
  const { isLoading: isReEnablingProductionPlace, isSuccess: hasReEnabledProductionPlace, mutate: reEnableProductionPlace } = useReEnableProductionPlace();
  const { isLoading: isFetchingAnimalsCount, isSuccess: hasFetchedAnimalsCount, data: animalsCount = {}} = useGetTotalAnimalsByProductionPlace(id);
  
  return {
    isFetchingProductionPlace,
    productionPlace,
    hasFetchedProductionPlace,
    refetchProductionPlace,
    isDisablingProductionPlace,
    hasDisabledProductionPlace,
    disableProductionPlace,
    isReEnablingProductionPlace,
    hasReEnabledProductionPlace,
    reEnableProductionPlace,
    isFetchingAnimalsCount,
    hasFetchedAnimalsCount,
    animalsCount,
  }

}